import React from 'react'
import { AppCta } from '../App/AppCta'

export const ProgramNotApproved = ({ children = null}) => {
  return (
    <div className="flex flex-col items-center bg-gp-violet-10">
      <h1 className="w-full md:max-w-5xl leading-snug font-bold text-3xl text-gp-violet px-16 pt-12 text-center sofia">
        Your coach is personalizing your program.
      </h1>
      <div className="w-full pt-8 px-8 md:max-w-xl text-center">
        <span className="leading-loose">
          You will receive an email when your program is ready. In the meantime,
          download the Goodpath app.
        </span>
      </div>
      <AppCta />
      {children}
    </div>
  )
}
