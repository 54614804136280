import React from 'react'
import womanWithPhone from '../../images/app/Womanwithphone@2x.png'
import { DownloadFromAppStoreButton } from './DownloadFromAppStoreButton'

export const AppCta = () => {
  return (
    <>
      <div className="w-full md:max-w-xl flex flex-col items-center bg-gp-violet-10">
        <DownloadFromAppStoreButton
          className="w-64 pt-8"
          orientation="vertical"
        />
        <img
          className="pt-6"
          src={womanWithPhone}
          alt="Woman smiling holding a phone"
        />
      </div>
      <div className="w-full md:max-w-xl flex flex-col items-center bg-white">
        <h1 className="w-full md:max-w-xl leading-snug font-bold text-3xl text-gp-violet px-16 pt-8 text-center sofia">
          Care done differently
        </h1>
        <div className="w-full pt-8 px-8 md:max-w-xl text-center">
          <span className="leading-loose">
            Track your daily accomplishments and see your progress over time as
            your condition improves. Work with your coach to reach your goals
            and keep you on track.
          </span>
        </div>
        <DownloadFromAppStoreButton
          className="w-64 py-8"
          orientation="vertical"
        />
      </div>
    </>
  )
}
