import React from 'react'
import { NoIndex } from '../components/NoIndex'
import { ProgramNotApproved } from '../components/Dashboard/ProgramNotApproved'
import PrivateRoute from '../components/PrivateRoute'

export default function OrderConfirmed({ location }) {
  return (
    <PrivateRoute location={location}>
      <NoIndex />
      <ProgramNotApproved />
    </PrivateRoute>
  )
}
